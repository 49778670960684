/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-smooth-scroll@2.2.0/jquery.smooth-scroll.min.js
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 * - /npm/flexslider@2.6.4/jquery.flexslider.min.js
 * - /npm/jquery-parallax@1.0.1/scripts/jquery.parallax-1.1.3.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/js-storage@1.1.0/js.storage.min.js
 * - /npm/jquery.quicksearch@2.4.0/dist/jquery.quicksearch.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
